/* .container {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  z-index: 50;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: auto;
} */

.inner-container42322 {
  /*May need to make 300px a global variable = sidebar width */
  width: calc(100vw - 300px);
  margin: 0px 0px 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.container {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Consider if this suits your needs better */
}

.container-z {
  position: fixed;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Consider if this suits your needs better */
}

.inner-container {
  margin: auto; /* Simplifies centering */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* .card {
  min-width: 600px;
  margin: 0px auto;
  background-color: var(--ea-beige);
 
}

.card {
  min-width: 600px;
  margin: 0px auto;
  background-color: transparent;
 
} */

.card,
.card2 {
  max-height: 85vh; /* Adjust based on your needs */
  max-width: 80vw;
  overflow-y: auto; /* Enables scrolling for the content */
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box; /* Includes padding in the element's total width and height */
  background-color: var(--ea-white);
}

.card50vw {
  max-height: 85vh; /* Adjust based on your needs */
  max-width: 50vw;
  overflow-y: auto; /* Enables scrolling for the content */
  overflow-x: hidden;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box; /* Includes padding in the element's total width and height */
  background-color: var(--ea-white);
}

@media only screen and (max-width: 1000px) {
  .inner-container {
    width: 100vw;
  }

  .card {
    min-width: var(--width-clamp);
  }
}

@media only screen and (max-width: 430px) {
  .container {
    top: 15px;
    width: 100%;
  }

  .inner-container {
    width: 100%;
  }
}
