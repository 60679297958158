
/* 
.product-search {
  padding-right: 40px;
} */



.container {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 16px;
}

.container2 {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

#input-label {
  display: none;
}





.clear-btn {
  position: absolute;
  left: 221px;
  top: 9px;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 4;
}

.search_placeholder {
  position: absolute;
  right: 204px;
  bottom: -0.5px;
  transform: translateY(-50%);
  background-color: transparent;
  opacity: 50%;
  border: none;
  cursor: pointer;
  z-index: 4;
}


.search_placeholder2 {
  position: absolute;
  right: 204px;
  top: 21px;
  transform: translateY(-50%);
  background-color: transparent;
  opacity: 50%;
  border: none;
  cursor: pointer;
  z-index: 4;
}


.product-search {
  font-size: 0.9rem;
  color: grey;
  min-width: 198px;
  height: 100%;
  padding: 0 0 0 1rem;
  background: white;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 6px;
  padding-left: 38px;
  outline: none;
  padding-right: 32px;
  left: 12px;
  position: static;
  background-image: url(/icons/SearchGrey.svg);
  background-repeat: no-repeat;
  background-size: 6%;
  /* background-position: left; */
  background-position-y: center;
  background-position-x: 15px;
}

.product-search::placeholder {
  color: grey;
  opacity: 0.7;
}

.fa-times:hover {
  cursor: pointer;
}

.fas.fa-times {
  color: #999;
}

.fas.fa-times:hover {
  color: #666;
}

.results-wrap {
  width: 100%;
  min-height: 0rem;
  max-height: auto;
  padding-left: 1.5rem;
  box-sizing: border-box;
  /* margin-top: -2rem; */
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}

.list-item {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 0.8rem;
  line-height: 2rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
  align-items: center;
}

.list-item a {
  color: var(--ea-black);
  text-decoration: none;
}

.list-item a:hover {
  color: #4690FF;
  transition: 200ms ease-in;
}

.list-name {
  padding-left: 10px;

}

.flex {
  display: flex;
  
}

.list-ul {
  list-style: none;
    margin: 0 auto;
    padding: 0 0 0 0;
}