.container {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  background-color: var(--ea-beige);
  color: var(--ea-black);
  height: var(--navbar-height);
  z-index: 50;
}

.inner-container {
  height: inherit;
  width: var(--width-clamp);
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 50px 0px 0px;
}

.appsumo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 30px;
}

.login-button {
  margin: 0px 0px 0px auto;
  display: flex;
  column-gap: 19px;
}

.mobile-menu {
  display: none;
}

.menu-button {
  display: none;
}

.close-button {
  display: none;
}

.appsumo-x {
  margin-right: 10px;
  margin-left: 10px;
}

@media only screen and (max-width: 750px) {
  .links-container {
    display: none;
  }

  .login-button {
    display: none;
  }

  .mobile-menu {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: var(--ea-beige);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    z-index: 100;
  }

  .mobile-menu > a {
    cursor: pointer;
  }

  .menu-button {
    display: block;
    margin: 0px 0px 0px auto;
  }

  .close-button {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 50px;
    right: 50px;
    background: none;
    border: none;
  }
}
