.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  background-color: var(--ea-beige);
  padding: 0px 10px;
  height: 30px;
  width: fit-content;
  border: 1px solid var(--ea-black);
  border-radius: 4px;

  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 750px) {
  .container {
    height: 25px;
    padding: 0px 8px;
  }
}
