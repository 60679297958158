.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--navbar-height));
  background-color: var(--ea-beige);
}

.container > h2 {
  opacity: 0.5;
}
