.button {
  position: relative;
  cursor: pointer;
  border: 1px solid var(--ea-black);
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 7.5px;
  height: 40px;
  padding: 0px 22px;
  border-radius: 4px;
  background-color: var(--ea-beige);
  color: black;
  width: fit-content;

  transition: all 0.2s ease-in-out;
}

.button > p {
  font-weight: 400;
}

.button > b {
  font-weight: 500;
}

.button:disabled {
  opacity: 0.5;
}

.image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.no-hover {
  pointer-events: auto; 
}

.no-hover:hover {
  filter: none;
  transform: none;
  box-shadow: none;
}

.tooltip {
  --tooltip-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: var(--tooltip-height);
  position: absolute;
  left: -60%;
  right: -60%;
  /* text-align: center; */
  top: calc(-1 * (var(--tooltip-height) + 10px));
}

.tooltip > p {
  line-height: var(--tooltip-height);
  padding: 0px 10px;
  background-color: var(--ea-dark-gray);
  color: var(--ea-white);
  border-radius: 4px;
  opacity: 0;
  transition: 0.5s;
}

.button:hover:not(:disabled) {
  filter: brightness(0.85);
  transform: scale(1.05);
}

.button:hover > .tooltip > p {
  opacity: 1;
}

.button:disabled > .tooltip {
  display: none;
}

.button:disabled {
  cursor: default;
}

.shadow {
  box-shadow: 6px 6px var(--ea-black);
}

.shadow:hover {
  box-shadow: 10px 10px var(--ea-black);
}

.extra-large {
  height: 64px;
}

.extra-small {
  height: 24px;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.box-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 750px) {
  .button {
    column-gap: 10px;
    padding: 0px 15px;
  }
}
